import React, { useEffect, useState } from "react";
import ModalComponent from "../../../common/ModalComponent";
import constant, { ModelType, ToastType } from "../../../../helper/constant";
import { useParams } from "react-router-dom";
import { useAIModelContext } from "../../../../context/ai-model.context";
import SocialMediaResult from "./social/SocialMediaResult";
import RegressionResult from "./regression/RegressionResult";
import ImageClassificationAI from "./image-classification/ImageClassificationAI";
import DocumentAI from "./document-ai/DocumentAI";
import KnowledgeAI from "./knowledge-ai/KnowledgeAI";
import RegressionExplainable from "./regression/RegressionExplainable";
import TimeSeriesExplainable from "./regression/TimeSeriesExplainable";
import { getEvaluateAIData } from "../../../../service/ai-model.service";
import { useAppContext } from "../../../../context/app.context";
import RegressionEvalaute from "./regression/RegressionEvaluate";
import SensorLogger from "./sensor-logger/SensorLogger";
import InferenceTab from "../InferenceTab";

const AIPredictionResult = () => {
  const params = useParams();
  const { getAIPredictData, selectedPredictionModel, predictionData } =
    useAIModelContext();
  const { showAlert } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [evaluateData, setEvaluateData] = useState(null);

  useEffect(() => {
    if (params.id) {
      getAIPredictData(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (selectedPredictionModel) setShowModal(true);
    else setShowModal(false);
  }, [selectedPredictionModel]);

  useEffect(() => {
    if (selectedPredictionModel) {
      const { taskId } = selectedPredictionModel;
      getEvaluateAIData(params.id, taskId)
        .then((result) => setEvaluateData(result))
        .catch((ex) => showAlert(ex?.message || ex, ToastType.ERROR));
    }
  }, [selectedPredictionModel]);

  const onModalClose = () => {
    console.log("close");
    setShowModal(false);
  };

  const renderConditionalResults = () => {
    if (showModal) {
      const { modelId } = selectedPredictionModel;
      switch (modelId) {
        case ModelType.SocialMedia:
          return <SocialMediaResult />;
        case ModelType.TabularRegression:
        case ModelType.TabularClassification:
        case ModelType.TimeSerialTabular:
          return <RegressionResult />;
        case ModelType.ImageClassification:
          return <ImageClassificationAI />;
        case ModelType.DocumentAI:
          return <DocumentAI />;
        case ModelType.KnowledgeAI:
          return <KnowledgeAI />;
        case ModelType.SensorLogger:
          return <SensorLogger />;
        default:
          return (
            <div className="d-flex justify-content-center align-items-center">
              <p className="">Comming soon ...</p>
            </div>
          );
      }
    }
  };

  const renderDynamicExplainable = () => {
    if (showModal) {
      const { modelId } = selectedPredictionModel;
      switch (modelId) {
        case ModelType.TabularClassification:
        case ModelType.TabularRegression:
          return <RegressionExplainable />;
        case ModelType.TimeSerialTabular:
          return <TimeSeriesExplainable />;
        default:
          return (
            <div className="d-flex justify-content-center align-items-center">
              <p className="">Comming soon ...</p>
            </div>
          );
      }
    }
  };

  const renderDynamicEvaluate = () => {
    if (selectedPredictionModel) {
      const { modelId } = selectedPredictionModel;
      switch (modelId) {
        case ModelType.TabularClassification:
        case ModelType.TabularRegression:
          return <RegressionEvalaute data={evaluateData} />;
        case ModelType.TimeSerialTabular:
          return <TimeSeriesExplainable />;
        default:
          return (
            <div className="d-flex justify-content-center align-items-center">
              <p className="">Comming soon ...</p>
            </div>
          );
      }
    }
  };

  const renderDynamicInference = () => {
    if (selectedPredictionModel) {
      const { modelId } = selectedPredictionModel;
      switch (modelId) {
        case ModelType.TabularClassification:
        case ModelType.TabularRegression:
          return <RegressionEvalaute data={evaluateData} />;
        case ModelType.TimeSerialTabular:
          return <TimeSeriesExplainable />;
        default:
          return (
            <div className="d-flex justify-content-center align-items-center">
              <p className="">Comming soon ...</p>
            </div>
          );
      }
    }
  };

  const renderPredictionTabs = () => {
    return (
      <div className="popuptabs evaluate-ai">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active capitalize"
              id="prediction-tab"
              data-toggle="tab"
              href="#prediction"
              role="tab"
              aria-controls="prediction"
              aria-selected="true"
            >
              AI Prediction
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link capitalize"
              id="explainable-tab"
              data-toggle="tab"
              href="#explainable"
              role="tab"
              aria-controls="explainable"
              aria-selected="false"
            >
              Explainable AI
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link capitalize"
              id="evaluate-tab"
              data-toggle="tab"
              href="#evaluate"
              role="tab"
              aria-controls="evaluate"
              aria-selected="false"
            >
              Evaluate AI
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link capitalize"
              id="evaluate-tab"
              data-toggle="tab"
              href="#inference"
              role="tab"
              aria-controls="inference"
              aria-selected="false"
            >
              visualize AI
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active mt-2"
            id="prediction"
            role="tabpanel"
            aria-labelledby="prediction-tab"
          >
            {renderConditionalResults()}
          </div>
          <div
            className="tab-pane fade mt-2"
            id="explainable"
            role="tabpanel"
            aria-labelledby="explainable-tab"
          >
            {renderDynamicExplainable()}
          </div>
          <div
            className="tab-pane fade mt-2"
            id="evaluate"
            role="tabpanel"
            aria-labelledby="evaluate-tab"
          >
            {renderDynamicEvaluate()}
          </div>
          <div
            className="tab-pane fade mt-2"
            id="inference"
            role="tabpanel"
            aria-labelledby="inference-tab"
          >
            <InferenceTab />
          </div>
        </div>
      </div>
    );
  };

  const render = () => {
    if (selectedPredictionModel) {
      const { model_name } = selectedPredictionModel;
      return (
        <ModalComponent
          navigateTo={`/${constant.PAGES.AI_PREDICTION}/${params.id}`}
          full
          title={`${model_name}`}
          show={showModal}
          handleClose={onModalClose}
        >
          {renderPredictionTabs()}
        </ModalComponent>
      );
    }
  };
  return render();
};

export default AIPredictionResult;
